import React from "react";
import { Box, Button, Container, Grid, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import Home_Image from "../assets/images/seasons.png";

export const Home: React.FC = () => {
  return (
    <Box sx={{ py: 4 }}>
      <Container maxWidth="lg">
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Typography variant="h4" sx={{ mb: 2 }}>
              Welcome to All Season Tax Service
            </Typography>
            <Typography variant="body1" sx={{ mb: 2 }}>
              Have you ever wondered about those tax places that only open for
              tax season and then close? Well, we are not one of those places.
              We are open year round to serve you. We are a full service tax
              preparation and bookkeeping agency serving individuals and
              businesses in Irving, TX and the surrounding areas.
            </Typography>
            <Typography variant="body1" sx={{ mb: 2 }}>
              We offer a broad range of services for business owners,
              executives, and independent professionals.
              <br />
              We are affordable, experienced, and friendly.
            </Typography>
            <Button
              variant="contained"
              color="primary"
              component={Link}
              to="/services"
            >
              Our Services
            </Button>
          </Grid>
          <Grid item xs={12} md={6}>
            <img
              src={Home_Image}
              alt="Tax Business"
              style={{ width: "100%", height: "auto" }}
            />
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default Home;
