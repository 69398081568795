import { Box, Container } from "@mui/material";
import { FC, PropsWithChildren } from "react";
import { Header } from "./Header";
import { Footer } from "./Footer";
import { Outlet } from "react-router-dom";

const sections = [
  { title: "Home", url: "/" },
  { title: "About", url: "/about" },
  { title: "Services", url: "/services" },
  { title: "Contact", url: "/contact" },
];

export const Layout: FC<PropsWithChildren> = () => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      height="100%"
      sx={{ backgroundColor: "#F2FAEF" }}
    >
      <Header title="All Season Tax Service" sections={sections} />
      <Container maxWidth="lg"> <Outlet /> </Container>
      <Footer
        title="For your all season tax needs"
        description="The best tax service available at your convinience"
      />
    </Box>
  );
};
