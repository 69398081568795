import * as React from "react";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import { Button } from "@mui/material";
import Logo from "../assets/images/cropped-EmblemaV3-270x270.png";

interface HeaderProps {
  sections: ReadonlyArray<{
    title: string;
    url: string;
  }>;
  title: string;
}

export const Header = (props: HeaderProps) => {
  const { sections, title } = props;

  return (
    <React.Fragment>
      <Toolbar
        sx={{
          borderBottom: 1,
          borderColor: "divider",
          backgroundColor: "background.paper",
        }}
      >
        <img src={Logo} height={52} width={52} alt="logo" />
        <Typography
          component="h2"
          variant="h5"
          color="inherit"
          align="left"
          fontFamily={"Helvetica Neue, Helvetica, Arial, sans-serif"}
          noWrap
          sx={{ flex: 1, ml: 2 }}
        >
          {title}
        </Typography>

        {sections.map((section) => (
          <Button
            LinkComponent={Link}
            key={section.title}
            variant="text"
            href={section.url}
            sx={{
              p: 1,
              flexShrink: 0,
              textDecoration: "none",
            }}
          >
            {section.title}
          </Button>
        ))}
      </Toolbar>
    </React.Fragment>
  );
};
