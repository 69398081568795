import TruckDriverWithReipts from "assets/images/truck-driver-with-receipts.jpg";
import PreparingTaxesAtHome from "assets/images/preparing-taxes-at-home.jpg";
import PreparingTaxesAtHome2 from "assets/images/preparing-taxes-at-home2.jpg";
import { Post } from "types";
import raw from "raw.macro";


export const POSTS: Post[] = [
    {
      title: "Retail and tax filing",
      date: new Date("12/11/2023"), // make this a javascript date object,
      description:
        "This is a wider card with supporting text below as a natural lead-in to additional content.",
      image: PreparingTaxesAtHome,
      imageLabel: "Image Text",
      markdown: raw("../assets/posts/retail-and-tax-filing.md"),
      link: "retail-and-tax-filing",
      linkLabel: "Continue reading…",
    },
    {
      title: "Trucking and handling taxes",
      date: new Date("12/11/2023"),
      description:
        "This is a wider card with supporting text below as a natural lead-in to additional content.",
      image: TruckDriverWithReipts,
      imageLabel: "Image Text",
      markdown: raw("../assets/posts/trucking-and-handling-taxes.md"),
      link: "trucking-and-handling-taxes",
      linkLabel: "Continue reading…",
    },
    {
      title: "Owning a restaurant and filing taxes",
      date: new Date("12/11/2023"),
      description:
        "As a restaurant owner, you have a lot on your plate. From managing staff to ensuring customer satisfaction, you have a lot to do. One thing you shouldn’t have to worry about is your taxes.",
      image: PreparingTaxesAtHome2,
      imageLabel: "Image Text",
      markdown: raw("../assets/posts/owning-a-restaurant-and-filing.md"),
      link: "owning-a-restaurant-and-filing",
      linkLabel: "Continue reading…",
    }
  ];