import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardActionArea from "@mui/material/CardActionArea";
import CardContent from "@mui/material/CardContent";
import { Post } from "types";
import { Link } from "react-router-dom";
import { Box } from "@mui/material";

interface FeaturedPostProps {
  post: Post;
}

export const FeaturedPost = (props: FeaturedPostProps) => {
  const { post } = props;

  return (
    <Grid item xs={12} md={6}>
      <CardActionArea LinkComponent={Link} component={Link} to={post.link}>
        <Card
          sx={{
            display: "flex",
            flexDirection: "column",
            height: 200,
            width: 300,
          }}
        >
          <Box
            sx={{
              position: "absolute",
              top: 0,
              bottom: 0,
              right: 0,
              left: 0,
              backgroundImage: `url(${post.image})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              opacity: 0.3,
              zIndex: -1,
            }}
          />
          <CardContent
            sx={{
              flexGrow: 1,
              position: "relative",
              zIndex: 1,
              backgroundColor: "rgba(255, 255, 255, 0.7)",
            }}
          >
            <Typography variant="h5" component="div">
              {post.title}
            </Typography>
            <Typography
              variant="body2"
              paragraph
              sx={{
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "wrap",
                display: "-webkit-box",
                WebkitLineClamp: 3, // number of lines to display
                WebkitBoxOrient: "vertical",
              }}
            >
              {post.description}
            </Typography>
            <Typography variant="body2" color="primary">
              Continue reading...
            </Typography>
          </CardContent>
        </Card>
      </CardActionArea>
    </Grid>
  );
};
