import Grid from "@mui/material/Grid";
import { FeaturedPost, MainFeaturedPost } from "components";

import { Post } from "types";
import { POSTS } from "core/constants";
import { Outlet } from "react-router-dom";
import { Box } from "@mui/material";


function getMostRecentPost(posts: Post[]) {
  return posts.sort((a, b) => b.date.getTime() - a.date.getTime())[0];
}
export const Services = () => {
  const mainFeaturedPost = getMostRecentPost(POSTS);
  return (
    <main>
      <MainFeaturedPost {...mainFeaturedPost} />
      <Box display="flex" flexDirection="row" overflow="auto" gap={2} p={1}>
        {POSTS.map((post) => (
          <FeaturedPost key={post.title} post={post} />
        ))}
      </Box>
      <Grid  spacing={5} sx={{ mt: 3 }}>
          <Outlet />
      </Grid>
    </main>
  );
};
