import { createBrowserRouter } from "react-router-dom";
import { Home, About, Services } from "pages";
import { Layout } from "components/Layout";
import Contact from "pages/Contact";
import { ServiceDetail } from "components";

export const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <Layout />
    ),
    children: [
      {
        index: true,
        element: (
          <Home />
        ),
      },
      {
        path: "about",
        element: (
          <About />
        ),
      },
      {
        path: "services",
        element: (
          <Services />
        ),
        children: [
          {
            path: ":serviceTitle",
            element: (
              <ServiceDetail />
            ),
          },
        ],
      },
      {
        path: "contact",
        element: (
          <Contact />
        ),
      },
    ],
  },

]);
