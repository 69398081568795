import { keyframes } from "@mui/material";

export const animateIn = keyframes`
    0% { opacity: 0; transform: translateY(100%); }
    100% { opacity: 1; transform: translateY(0); }
`;

export const animateOut = keyframes`
    0% { opacity: 1; transform: translateY(0); }
    100% { opacity: 0; transform: translateY(-100%); }
`;