import React, { FC, useEffect } from "react";
import { Box, Paper, Typography, styled, useMediaQuery } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import headerImage from "assets/images/about-image.jpg";
import visionImage from "assets/images/summer-thailand.jpg";
import missionImage from "assets/images/fall-extension.jpg";
import valuesImage from "assets/images/spring.jpg";

const Item = styled(Paper)(({ theme }) => {
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return {
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(2),
    textAlign: "left",
    color: theme.palette.text.secondary,
    textIndent: "64px",
    display: "flex",
    flexDirection: isSmallScreen ? "column" : "row",
    justifyContent: "center",
    gap: "16px",
  };
});

const GridFadeIn = styled(Grid)`
  &.hidden {
    opacity: 0;
    filter: blur(5px);
    transform: translateX(-100%);
    transition:
      opacity 1s,
      filter 1s,
      transform 0.5s;
  }
  &.fadeRight {
    opacity: 1;
    filter: blur(0);
    transform: translateX(0);
  }
`;

export const About: FC = () => {
  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add("fadeRight");
        }
      });
    });
    const elements = document.querySelectorAll(".hidden");
    elements.forEach((element) => {
      observer.observe(element);
    });
    return () => {
      elements.forEach((element) => {
        observer.unobserve(element);
      });
    };
  }, []);

  return (
    <Box sx={{ my: 2 }}>
      <Grid spacing={2} direction="column" rowGap={5}>
        <Grid
          xs={12}
          sm={6}
          md={4}
          spacing={2}
          gap="16px"
          display="flex"
          flexDirection="column"
        >
          <Typography variant="h4">About Us</Typography>
          <div
            style={{
              width: "100%",
              maxWidth: 1200,
              height: 400,
              backgroundImage: `url(${headerImage})`,
              backgroundSize: "cover",
              backgroundPosition: "0% 60%",
              backgroundRepeat: "no-repeat",
              borderRadius: 6,
              boxShadow: "0 0 20px 20px rgba(0, 0, 0, 0.5) inset",
            }}
          />
          <Typography variant="body2">
            Texas born and raised we are a family owned and operated business.
            We have been serving the Dallas community for over 10 years. We are
            dedicated to providing expert tax services, personalized financial
            guidance, and culturally sensitive support. With a commitment to
            integrity, professionalism, and accessibility, we aim to be your
            trusted partner on the path to economic well-being. Together,
            let&apos;s navigate the complexities of taxes and finances, ensuring
            a brighter future for you, your family, and our community.
          </Typography>
        </Grid>
        <GridFadeIn
          xs={12}
          md={12}
          lg={12}
          xl={12}
          columnGap={2}
          direction="column"
          className="hidden"
        >
          <Typography variant="h5">Vision</Typography>
          <Item>
            <Typography variant="body1">
              Is to be the trusted cornerstone of financial success in our local
              community. We envision a future where every individual and
              business in Dallas can confidently navigate the complexities of
              taxes and finances, empowered by our expertise and guidance. Our
              commitment to clarity, integrity, and exceptional service drives
              us to exceed expectations, delivering peace of mind to our
              clients. As we look ahead, we aspire to expand our footprint while
              maintaining the personalized attention and community engagement
              that define us. We are dedicated to fostering financial
              well-being, one client at a time, and building a legacy of trust
              for generations to come.
            </Typography>
            <img
              src={visionImage}
              width={300}
              height={300}
              alt="Person enjoyng a summer beach in Thailand"
            />
          </Item>
        </GridFadeIn>
        <GridFadeIn xs={12} md={12} lg={12} spacing={2} className="hidden">
          <Typography variant="h5" align="left">
            Mission
          </Typography>

          <Item>
            <img
              src={missionImage}
              width={300}
              height={300}
              alt="building with fall leaves around"
            />
            <Typography variant="body1">
              Empower individuals and businesses within the vibrant Irving,
              Texas, Hispanic community to achieve financial success and
              security. We are dedicated to providing expert tax services,
              personalized financial guidance, and culturally sensitive support.
              With a commitment to integrity, professionalism, and
              accessibility, we aim to be your trusted partner on the path to
              economic well-being. Together, let&apos;s navigate the
              complexities of taxes and finances, ensuring a brighter future for
              you, your family, and our community.
            </Typography>
          </Item>
        </GridFadeIn>
        <GridFadeIn xs={12} md={12} lg={12} spacing={2} className="hidden">
          <Typography variant="h5">Values</Typography>
          <Item>
            <Typography variant="body1">
              We are driven by a steadfast commitment to our local community,
              rooted in the values that define us. We believe in the power of
              integrity, transparency, and unwavering ethics as the cornerstones
              of our business. Our journey is guided by a profound sense of
              responsibility towards our customers, employees, and the
              environment. We pledge to deliver the highest quality products and
              services, always seeking ways to exceed your expectations. With
              empathy and inclusivity at our core, we aim to create a diverse
              and welcoming environment that reflects the richness of our
              community. Sustainability is not just a buzzword; it&apos;s a
              promise we uphold, striving to minimize our impact on the
              environment while contributing to the long-term prosperity of our
              neighbors. As we grow, we remain dedicated to being a reliable,
              accountable, and customer-centric partner, fostering trust within
              our community. Our commitment to local sourcing and social
              responsibility is unwavering. We are here not just for today but
              for the long term, focused on building lasting relationships and
              making a positive difference in the lives of those we serve. Thank
              you for allowing us to be a part of this wonderful community, and
              we look forward to continuing this journey together, grounded in
              our values and dedicated to your success.
            </Typography>
            <img
              src={valuesImage}
              width={300}
              height={300}
              alt="field of various colors of tulips in the summer"
            />
          </Item>
        </GridFadeIn>
      </Grid>
    </Box>
  );
};
