import React, { SyntheticEvent, useRef, useState } from "react";
import {
  Grid,
  Typography,
  TextField,
  Button,
  Snackbar,
  SnackbarCloseReason,
  Alert,
  AlertColor,
} from "@mui/material";
import emailjs from "@emailjs/browser";
emailjs.init({
  publicKey: "TRjjNY-iA8gi9ysFE",
  // Do not allow headless browsers
  blockHeadless: true,
  blockList: {
    // Block the suspended emails
    list: ["foo@emailjs.com", "bar@emailjs.com"],
    // The variable contains the email address
    watchVariable: "userEmail",
  },
  limitRate: {
    // Set the limit rate for the application
    id: "app",
    // Allow 1 request per 10s
    throttle: 10000,
  },
});
export const Contact: React.FC = () => {
  const form = useRef<HTMLFormElement>(null);
  const [snackbarContent, setSnackbarContent] = useState<{
    message: string;
    severity: string;
  } | null>(null);
  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!form.current) return;
    emailjs
      .sendForm("service_qektbyt", "template_i1rvid9", form.current, {
        publicKey: "TRjjNY-iA8gi9ysFE",
      })
      .then(
        () => {
          setSnackbarContent({
            message: "Email sent successfully",
            severity: "success",
          });
        },
        (error) => {
          setSnackbarContent({
            message: `Email failed to send: ${error}`,
            severity: "error",
          });
        }
      );
  };

  const handleClose = (
    // biome-ignore lint/suspicious/noExplicitAny: <explanation>
    _event: Event | SyntheticEvent<any, Event>,
    reason?: SnackbarCloseReason
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setSnackbarContent(null);
  };

  return (
    <Grid container spacing={2} py={2}>
      <Grid item xs={12}>
        <Typography variant="h4">Contact Us</Typography>
      </Grid>
      <Grid item xs={12} md={6} display="flex" flexDirection="column" gap={2}>
        <Typography variant="h5">Get in Touch</Typography>
        <form ref={form} onSubmit={handleSubmit}>
          <Grid container spacing={2} gap={2}>
            <Grid item xs={12}>
              <TextField name="from_name" label="Name" fullWidth required />
            </Grid>
            <Grid item xs={12}>
              <TextField
                name="reply_to"
                label="Email"
                type="email"
                fullWidth
                required
              />
            </Grid>
            <Grid item xs={12}>
              <TextField name="phone" label="Phone" fullWidth required />
            </Grid>
            <Grid item xs={12}>
              <TextField
                name="message"
                label="Message"
                multiline
                rows={4}
                fullWidth
                required
              />
            </Grid>
            <Grid item xs={12}>
              <Button type="submit" variant="contained" color="primary">
                Submit
              </Button>
            </Grid>
          </Grid>
        </form>
      </Grid>
      <Grid item xs={12} md={6}>
        <Typography variant="h5">Visit Us</Typography>
        <Typography variant="body1">
          128 E Sixth Street
          <br />
          Suite B
          <br />
          Irving, TX 75060
        </Typography>
        <iframe
          title="All Season Tax Service Location"
          width="100%"
          height="300"
          frameBorder="0"
          style={{ border: 0 }}
          src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d13413.5803763971!2d-96.945705!3d32.808295!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xd5d093660752f953!2sAll+Season+Tax+Service!5e0!3m2!1ses!2sus!4v1470689426916"
          allowFullScreen
        />
        <Typography variant="h5">Phone</Typography>
        <Typography variant="body1">(972) 554-1900</Typography>
        <Typography variant="h5">Email</Typography>
        <Typography variant="body1">allseasontaxservice@gmail.com</Typography>
      </Grid>
      {snackbarContent ? (
        <Snackbar
          open={Boolean(snackbarContent)}
          autoHideDuration={6000}
          onClose={handleClose}
        >
          <Alert
            onClose={handleClose}
            severity={snackbarContent.severity as AlertColor}
            sx={{ width: "100%" }}
          >
            {snackbarContent.message}
          </Alert>
        </Snackbar>
      ) : null}
    </Grid>
  );
};

export default Contact;
