import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import { Markdown } from "./Markdown";
import { useParams } from 'react-router-dom';
import { POSTS } from "core";
import { FC } from "react";

interface ServiceDetailProps {
  title?: string;
}

export const ServiceDetail: FC<ServiceDetailProps> = ({ title }) => {
  const { serviceTitle } = useParams();
 
  const post = POSTS.find((post) => post.link === serviceTitle);
  const areBothDefined = Boolean(serviceTitle) && Boolean(post);
  return (
    <Grid
      item
      xs={12}
      md={8}
      sx={{
        "& .markdown": {
          py: 3,
        },
      }}
    >
      <Typography variant="h6" gutterBottom>
        {title}
      </Typography>
      <Divider />
        {areBothDefined && <Markdown className="markdown">
          {post!.markdown}
        </Markdown>}
    </Grid>
  );
};
