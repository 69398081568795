import {
  Link as RouterLink,
  LinkProps as RouterLinkProps,
} from "react-router-dom";
import { LinkProps } from "@mui/material/Link";
import { createTheme } from "@mui/material";
import { forwardRef } from "react";

const LinkBehavior = forwardRef<
  HTMLAnchorElement,
  Omit<RouterLinkProps, "to"> & { href: RouterLinkProps["to"] }
>((props, ref) => {
  const { href, ...other } = props;
  return <RouterLink ref={ref} to={href} {...other} />;
});

export const theme = createTheme({
  palette: {
    primary: {
      main: "#009688",
      dark: "#00796B",
      light: "#B2DFDB",
      contrastText: "#FFFFFF",
    },
    secondary: {
      main: "#FF9800",
      dark: "#F57C00",
      light: "#FFE0B2",
      contrastText: "#212121",
    },
  },

  components: {
    MuiCssBaseline: {
      styleOverrides: `
        body {
          height: 100vh;
        }
       
      `,
    },
    MuiLink: {
      defaultProps: {
        component: LinkBehavior,
      } as LinkProps,
    },
    MuiButtonBase: {
      defaultProps: {
        LinkComponent: LinkBehavior,
      },
    },
  },
});
